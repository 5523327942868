import React from 'react';

import { FunctionField, ShowButton, List, Datagrid, TextField, TextInput, Filter } from 'react-admin';

import MallIntegrationEmpty from './empty';
import ResendButton from './resendButton';

import StringUtil from '../../utils/string';

const MallIntegrationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Company Name" source="companyName" allowEmpty />
    <TextInput label="Outlet Name" source="outletName" allowEmpty />
    <TextInput label="POS Name" source="posName" allowEmpty />
  </Filter>
);

const MallIntegrationList = (props) => {
  const getAuthor = (record) => {
    let author = '';
    if (record) {
      author = record.updatedBy ? record.updatedBy : record.createdBy;
    }

    return StringUtil.titleCase(author);
  }

  return (
    <List
      title='Mall Integrations'
      empty={<MallIntegrationEmpty />}
      filters={<MallIntegrationFilter />}
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <Datagrid>
        <TextField source="companyName" label="Company Name" />
        <TextField source="outletName" label="Outlet Name" />
        <TextField source="posName" label="POS Name" />
        <FunctionField label="Updated By" render={record => getAuthor(record)} />

        <ResendButton />

        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default MallIntegrationList;
