import React from 'react';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import BqIntegrationIcon from '@material-ui/icons/CompareArrows';

import { CreateButton } from 'react-admin';

const BqIntegrationEmpty = ({ basePath, resource }) => (
  <Box textAlign="center" m={1}>
    <BqIntegrationIcon style={{ fontSize: 140 }} />
    <Typography variant="h4" paragraph>
      No BQ integration available yet.
    </Typography>
    <Typography variant="body1">Do you want to add one?</Typography>
    <CreateButton className="primary" basePath={basePath} />
  </Box>
);

export default BqIntegrationEmpty;
