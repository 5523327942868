import React from 'react';

import { Edit, SimpleForm, TextInput, required } from 'react-admin';

import EditToolbar from '../common/adminOnlyDeleteToolbar';

const FormTitle = ({ record }) => {
  return <span>{`Edit ${record.templateName}`}</span>;
};

const MallCronTemplateEdit = ({ permissions, ...props }) => (
  <Edit title={<FormTitle />} {...props}>
    <SimpleForm toolbar={<EditToolbar permissions={permissions} />}>
      <TextInput source="templateName" validate={[required()]} />
      <TextInput source="scriptFilename" label="Script Filename" validate={[required()]} fullWidth />
      <TextInput source="scriptValue" label="Script Value" validate={[required()]} fullWidth multiline />
    </SimpleForm>
  </Edit >
);

export default MallCronTemplateEdit;
