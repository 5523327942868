import React from 'react';

import { Create, SimpleForm, TextInput, required } from 'react-admin';

const FormTitle = ({ record }) => {
  return <span>Create Mall Template</span>;
};

const MallCronTemplateCreate = (props) => (
  <Create title={<FormTitle />} {...props} label='Create Mall Template'>
    <SimpleForm redirect="list">
      <TextInput source="templateName" validate={[required()]} />
      <TextInput source="scriptFilename" label="Script Filename" validate={[required()]} fullWidth />
      <TextInput source="scriptValue" label="Script Value" validate={[required()]} fullWidth multiline />
    </SimpleForm>
  </Create>
);

export default MallCronTemplateCreate;
