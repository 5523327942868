import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  useDataProvider,
  SimpleForm,
  TextInput,
  FormDataConsumer
} from 'react-admin';


const FormCatcher = ({
  formData,
  record,
  setStartDate
}) => {
  return (
    <>
      <div className='autocompute-field'>
        <TextInput
          source="filename"
          label="Filename"
          disabled
          value={record.filename}
        />
      </div>
    </>
  )
};

const ResendButton = ({ record }) => {
  const dataProvider = useDataProvider();

  const [open, setOpen] = useState(false);

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!record) {
      console.log('Record not found.');
      return;
    }

    const { id } = record;
    dataProvider
      .resendBqIntegration(`resend/${record.id}`, { id })
      .then(({ data }) => {
        // Update queue and ping list
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        Resend
      </Button>

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Resend Mall Integration config data`}</DialogTitle>
        <DialogContent>
          <SimpleForm toolbar={null}>
            <FormDataConsumer>
              {formDataProps => (
                <FormCatcher {...formDataProps}
                  record={record}
                />
              )}
            </FormDataConsumer>
          </SimpleForm>
        </DialogContent>
        <DialogActions style={{ paddingRight: '30px' }}>
          <Button onClick={handleSubmit} color="primary">
            Send
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog >
    </>
  );
};

export default ResendButton;
