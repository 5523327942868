import React, { useState, useEffect } from 'react';
import kebabCase from 'lodash.kebabcase';
import { useForm } from 'react-final-form';

import {
  Create,
  SimpleForm,
  TextInput,
  required,
  AutocompleteInput,
  FormDataConsumer,
  useDataProvider
} from 'react-admin';

import CronTemplateNote from '../widget/cronTemplateNote';

import cronFormatValidator from '../utils/cronFormatValidator'

const FormCatcher = ({ formData, ...rest }) => {
  const form = useForm();

  const dataProvider = useDataProvider();

  const [cronTemplates, setCronTemplates] = useState('');
  const [cronTemplatesObject, setCronTemplatesObject] = useState({});

  const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
      obj[item[keyField]] = item
      return obj
    }, {});

  useEffect(() => {
    const cronFilter = { enabled: { $ne: false }, followUtc: { $nin: [true, 'true'] } };
    const cronPagination = { page: 1, perPage: 1000 };
    const cronSort = { field: 'name', order: 'ASC' };

    dataProvider
      .getList('cronTemplates', { filter: cronFilter, pagination: cronPagination, sort: cronSort })
      .then(({ data }) => {
        setCronTemplates(data);
        setCronTemplatesObject(arrayToObject(data, 'id'));

      })
      .catch((error) => {
        console.log(error);
      })
  }, [dataProvider]);

  return (
    <div className='bq-integration-form'>
      <div className='autocompute-field'>
        <TextInput
          source="customerName"
          label='Customer Name'
          validate={[required()]}
          onChange={(e) => {
            console.log(e);
            form.change('filename', kebabCase(e.currentTarget.value));
          }}
        />
      </div>

      <div className='autocompute-field'>
        <TextInput
          source="filename"
          label='Filename'
          validate={[required()]}
          onBlur={(e) => {
            form.change('filename', kebabCase(e.currentTarget.value));
          }}
        />
      </div>

      <div className='autocompute-field'>
        <AutocompleteInput
          source='dbServer'
          label='Database Server'
          validate={[required()]}
          choices={[
            {
              id: 'DB-SERVER 2',
              name: 'DB Server 2'
            },
            {
              id: 'DB-SERVER 3',
              name: 'DB Server 3'
            },
            {
              id: 'DB-SERVER 5',
              name: 'DB Server 5'
            },
            {
              id: 'INDO WEB SERVER',
              name: 'Indo Web Server'
            }
          ]}
        />
      </div>

      <div className='autocompute-field'>
        <TextInput
          source="customerDb"
          label='Customer DB'
          validate={[required()]}
        />
      </div>

      <div className='autocompute-field'>
        <AutocompleteInput
          source='cred'
          label='Cred'
          validate={[required()]}
          choices={[
            {
              id: 'bat-stocktake',
              name: 'bat-stocktake'
            },
            {
              id: 'ewsindobi',
              name: 'ewsindobi'
            },
            {
              id: 'ewssgbi',
              name: 'ewssgbi'
            },
            {
              id: 'fivestar',
              name: 'fivestar'
            },
            {
              id: 'kimeng',
              name: 'kimeng'
            },
            {
              id: 'mayson',
              name: 'mayson'
            },
            {
              id: 'qiji',
              name: 'qiji'
            },
            {
              id: 'ustars',
              name: 'ustars'
            }
          ]}
        />
      </div>

      <div className='autocompute-field'>
        <TextInput
          source="projectId"
          label='Project ID'
          validate={[required()]}
        />
      </div>

      <div className='autocompute-field'>
        <TextInput
          source="datasetId"
          label='Dataset ID'
          validate={[required()]}
        />
      </div>

      <div className='autocompute-field'>
        <AutocompleteInput
          source='postSql'
          label='Post SQL'
          validate={[required()]}
          choices={[
            {
              id: 'bakersoven',
              name: 'bakersoven'
            },
            {
              id: 'fnb',
              name: 'fnb'
            },
            {
              id: 'retail',
              name: 'retail'
            },
            {
              id: 'ustars',
              name: 'ustars'
            },
            {
              id: 'empty',
              name: 'empty'
            },
            {
              id: 'qiji',
              name: 'qiji'
            },
            {
              id: 'retail_with_item_attributes',
              name: 'retail_with_item_attributes'
            }
          ]}
        />
      </div>

      <div className='autocompute-field'>
        <AutocompleteInput
          source='globalGbq'
          label='Global GBQ'
          validate={[required()]}
          choices={[
            {
              id: 'true',
              name: 'Yes'
            },
            {
              id: 'false',
              name: 'No'
            }
          ]}
        />
      </div>

      <div className='autocompute-field'>
        <AutocompleteInput
          source="cronId"
          onChange={(e) => {
            form.change('scheduleTiming', cronTemplatesObject[e].cronExpression);
          }}
          choices={cronTemplates || []} />
      </div>

      <TextInput
        source="scheduleTiming"
        label='Schedule Timing'
        validate={[required()]}
        placeholder='0 4 * * * *'
      />

      <CronTemplateNote />
    </div>
  );
};

const validator = (values) => {
  const errors = {};

  if (values && values.scheduleTiming) {
    const isValidCronExpression = cronFormatValidator({ cronExpression: values.scheduleTiming });
    if (!isValidCronExpression) {
      errors.scheduleTiming = ['Invalid format'];
    }
  }

  return errors;
};

const FormTitle = ({ record }) => {
  return <span>Create BQ Integration</span>;
};

const BqIntegrationCreate = (props) => {
  return (
    <Create title={<FormTitle />}{...props}>
      <SimpleForm redirect="list" validate={validator}>
        <FormDataConsumer>
          {formDataProps => (
            <FormCatcher {...formDataProps} />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create >
  )
};

export default BqIntegrationCreate;