import React from 'react';

import { FunctionField, ShowButton, List, Datagrid, TextField, TextInput, Filter } from 'react-admin';

import BqIntegrationEmpty from './empty';
import ResendButton from './resendButton';

import StringUtil from '../../utils/string';

const BqIntegrationFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Customer Name" source="customerName" allowEmpty />
    <TextInput label="DB Server" source="dbServer" allowEmpty />
    <TextInput label="Customer DB" source="customerDb" allowEmpty />
  </Filter>
);

const BqIntegrationList = (props) => {
  const getAuthor = (record) => {
    let author = '';
    if (record) {
      author = record.updatedBy ? record.updatedBy : record.createdBy;
    }

    return StringUtil.titleCase(author);
  }

  return (
    <List
      title='BQ Integrations'
      empty={<BqIntegrationEmpty />}
      filters={<BqIntegrationFilter />}
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <Datagrid>
        <TextField source="customerName" label="Customer Name" />
        <TextField source="dbServer" label="DB Server" />
        <TextField source="customerDb" label="Customer DB" />
        <FunctionField label="Updated By" render={record => getAuthor(record)} />

        <ResendButton />

        <ShowButton />
      </Datagrid>
    </List>
  );
};

export default BqIntegrationList;
