import React, { useState } from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import moment from 'moment';

import {
  useDataProvider,
  SimpleForm,
  DateInput,
  TextInput,
  FormDataConsumer
} from 'react-admin';


const FormCatcher = ({
  formData,
  record,
  setStartDate,
  setPosId
}) => {
  const dateToday = moment().format('MM/DD/YYYY');

  return (
    <>
      <div className='autocompute-field'>
        <TextInput
          source="filename"
          label="Filename"
          disabled
          value={record.filename}
        />
      </div>

      <div className='autocompute-field'>
        <TextInput
          source="posId"
          label="POS ID"
          onChange={(e) => {
            setPosId(e.currentTarget.value)
          }}
        />
      </div>

      <div className='autocompute-field'>
        <DateInput
          label="Start Date"
          source="startDate"
          defaultValue={dateToday}
          onChange={(e) => {
            setStartDate(e.currentTarget.value)
          }}
        />
      </div>
    </>
  )
};

const ResendButton = ({ record }) => {
  const dataProvider = useDataProvider();

  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(moment().format('MM/DD/YYYY'));
  const [posId, setPosId] = useState('');

  const handleClickOpen = async () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    if (!record) {
      console.log('Record not found.');
      return;
    }

    const { id } = record;
    dataProvider
      .resendMallIntegration(`resend/${record.id}`, { id, startDate, posId })
      .then(({ data }) => {
        // Update queue and ping list
        setOpen(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Button
        size="small"
        variant="contained"
        color="primary"
        onClick={handleClickOpen}
      >
        Resend
      </Button>

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Resend Mall Integration config data`}</DialogTitle>
        <DialogContent>
          <SimpleForm toolbar={null}>
            <FormDataConsumer>
              {formDataProps => (
                <FormCatcher {...formDataProps}
                  record={record}
                  setStartDate={setStartDate}
                  setPosId={setPosId}
                />
              )}
            </FormDataConsumer>
          </SimpleForm>
        </DialogContent>
        <DialogActions style={{ paddingRight: '30px' }}>
          <Button onClick={handleSubmit} color="primary">
            Send
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog >
    </>
  );
};

export default ResendButton;
