import React from 'react';

import { FunctionField, SimpleShowLayout, Show, TextField } from 'react-admin';

const FormTitle = ({ record }) => {
  let title = '';
  if (record) {
    if (record.companyName) {
      title = `Mall integration for ${record.companyName} - ${record.posName}`;
    } else {
      title = `Edit integration for ${record.companyId} - ${record.posId}`;
    }

    return <span>{`${title}`}</span>;
  };
};

const MallIntegrationShow = (props) => {
  return (
    <Show title={<FormTitle />} {...props}>
      <SimpleShowLayout>
        <TextField
          source="companyName"
          label="Company Name"
        />

        <TextField
          source="companyId"
          label="Company Id"
        />

        <TextField
          source="filename"
          label="Filename"
        />

        <TextField
          source="posName"
          label="POS Name"
        />

        <FunctionField
          label="POS ID"
          render={(record) => `${record.posId || '-'}`}
        />

        <TextField
          source="scriptFilename"
          label="Template Filename"
        />

        <TextField
          source="scriptValue"
          label="Template Value"
        />

        <FunctionField
          label="Input / Output Folder"
          render={(record) => `${record.inputOutputFolder || '-'}`}
        />

        <TextField
          source="diffDay"
          label="Diff Day"
        />

        <TextField
          source="module"
          label="Module"
        />

        <TextField
          source="integrator"
          label="Integrator"
        />

        <TextField
          source="baseUrl"
          label="Base URL"
        />

        <TextField
          source="dbServer"
          label="Database Server"
        />

        <TextField
          source="customerDb"
          label="Customer DB"
        />

        <TextField
          source="timing"
          label="Houry / Daily"
        />

        <TextField
          source="tenantId"
          label="Tenant ID"
        />

        <TextField
          source="tillValue"
          label="Till Value"
        />

        <TextField
          source="ftpIp"
          label="FTP IP Address"
        />

        <TextField
          source="ftpUsername"
          label="FTP Username"
        />

        <TextField
          source="ftpPort"
          label="FTP Port"
        />

        <TextField
          source="scheduleTiming"
          label="Schedule Timing"
        />

        <TextField
          source="createdBy"
          label="Created By"
        />
      </SimpleShowLayout>
    </Show>
  )
};

export default MallIntegrationShow;
