import React from 'react';

import { FunctionField, ShowButton, List, Datagrid, TextField, EditButton, TextInput, Filter } from 'react-admin';

import MallTemplateEmpty from './empty';

import StringUtil from '../../utils/string';

const MallTemplateFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Template Name" source="templateName" allowEmpty />
    <TextInput label="Script Filename" source="scriptFilename" allowEmpty />
    <TextInput label="Created by" source="createdBy" allowEmpty />
  </Filter>
);

const MallTemplateList = (props) => {
  const getAuthor = (record) => {
    let author = '';
    if (record) {
      author = record.updatedBy ? record.updatedBy : record.createdBy;
    }

    return StringUtil.titleCase(author);
  }

  return (
    <List
      title='Mall Templates'
      empty={<MallTemplateEmpty />}
      filters={<MallTemplateFilter />}
      bulkActionButtons={false}
      sort={{ field: 'name', order: 'ASC' }}
      {...props}
    >
      <Datagrid>
        <TextField source="templateName" label="Template Name" />
        <TextField source="scriptFilename" label="Script Filename" />
        <FunctionField label="Updated By" render={record => getAuthor(record)} />
        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default MallTemplateList;
