import React from 'react';

import { Show, SimpleShowLayout, TextField, FunctionField } from 'react-admin';

const FormTitle = ({ record }) => {
  let title = '';
  if (record) {
    if (record.customerName) {
      title = `BQ integration for ${record.customerName}`;
    } else {
      title = 'Edit integration';
    }

    return <span>{`${title}`}</span>;
  };
};

const BqIntegrationShow = (props) => {
  return (
    <Show title={<FormTitle />} {...props}>
      <SimpleShowLayout>
        <TextField
          source="customerName"
          label="Customer Name"
        />

        <TextField
          source="filename"
          label="Filename"
        />

        <TextField
          source="dbServer"
          label="SQL DB Server Name"
        />

        <TextField
          source="customerDb"
          label="SQL DB Name"
        />

        <TextField
          source="cred"
          label="Cred"
        />

        <TextField
          source="projectId"
          label="Project ID"
        />

        <TextField
          source="datasetId"
          label="Dataset ID"
        />

        <TextField
          source="postSql"
          label="Post SQL"
        />

        <FunctionField
          label="Global GBQ"
          render={(record) => `${record.globalGbq === 'true' || record.globalGbq === true ? 'Yes' : 'No'}`}
        />

        <TextField
          source="createdBy"
          label="Created By"
        />


        <TextField
          source="scheduleTiming"
          label="Schedule Timing"
        />
      </SimpleShowLayout>
    </Show>
  )
};

export default BqIntegrationShow;
