import React from 'react';
import Box from '@material-ui/core/Box';

import Typography from '@material-ui/core/Typography';
import MallTemplateIcon from '@material-ui/icons/LocalMall';

import { CreateButton } from 'react-admin';

const MallTemplateEmpty = ({ basePath, resource }) => (
  <Box textAlign="center" m={1}>
    <MallTemplateIcon style={{ fontSize: 140 }} />
    <Typography variant="h4" paragraph>
      No mall template available yet.
    </Typography>
    <Typography variant="body1">Do you want to add one?</Typography>
    <CreateButton className="primary" basePath={basePath} />
  </Box>
);

export default MallTemplateEmpty;
