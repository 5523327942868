import React, { useState, useEffect } from 'react';
import kebabCase from 'lodash.kebabcase';
import { useForm } from 'react-final-form';

import {
  Create,
  SimpleForm,
  TextInput,
  required,
  AutocompleteInput,
  FormDataConsumer,
  useDataProvider,
  PasswordInput
} from 'react-admin';

import CronTemplateNote from '../widget/cronTemplateNote';

import cronFormatValidator from '../utils/cronFormatValidator'

const FormCatcher = ({ formData, ...rest }) => {
  const form = useForm();

  const dataProvider = useDataProvider();

  // const [posRecords, setPosRecords] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companiesObject, setCompaniesObject] = useState({});
  const [outlets, setOutlets] = useState([]);
  const [posIds, setPosIds] = useState([]);
  const [posIdsObject, setPosIdsObject] = useState([]);
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [mallTemplates, setMallTemplates] = useState([]);
  const [mallTemplatesObject, setMallTemplatesObject] = useState([]);
  const [cronTemplates, setCronTemplates] = useState('');
  const [cronTemplatesObject, setCronTemplatesObject] = useState({});

  const updateChoicesByCompany = async (companyId) => {
    setSelectedCompanyId(companyId);

    form.change('outletName', '');
    form.change('posId', '');

    if (!companyId) {
      setOutlets([]);
      return;
    }

    if (outlets) {
      await dataProvider
        .getOutletsByCompanyId(`pos/outlets/${companyId}`)
        .then(({ data = {} }) => {
          const companyOutlets = [];
          data.list.forEach((value) => {
            companyOutlets.push(
              {
                id: value,
                name: value
              }
            )
          });

          setOutlets(companyOutlets);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const updateChoicesByOutlet = async (outletName) => {
    form.change('posId', '');

    if (!outletName) {
      setPosIds([]);
      return;
    }

    if (posIds) {
      const filter = { companyId: selectedCompanyId, outletName };
      const pagination = { page: 1, perPage: 1000 };
      const sort = { field: 'posId', order: 'DESC' };

      await dataProvider
        .getList('pos', { filter, pagination, sort })
        .then(({ data }) => {
          const companyOutletPos = [];
          const companyOutletPosObj = [];
          data.forEach((value) => {
            companyOutletPos.push(
              {
                id: value.posId,
                name: value.posName
              }
            )
            companyOutletPosObj.push(value);
          });

          setPosIds(companyOutletPos);
          setPosIdsObject(arrayToObject(companyOutletPosObj, 'posId'))
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  const arrayToObject = (array, keyField) =>
    array.reduce((obj, item) => {
      obj[item[keyField]] = item
      return obj
    }, {})

  useEffect(() => {
    dataProvider
      .getCompanies('pos/companies')
      .then(({ data }) => {
        if (data && data.list) {
          const companyList = [];
          data.list.forEach((company) => {
            const { companyId, companyName } = company.id
            companyList.push({
              id: companyId,
              name: companyName
            })
          });

          setCompanies(companyList);
          setCompaniesObject(arrayToObject(companyList, 'id'));
        }
      })
      .catch((error) => {
        console.log(error);
      });

    const filter = {};
    const pagination = { page: 1, perPage: 1000 };
    const sort = { field: 'templateName', order: 'ASC' };

    dataProvider
      .getList('mallTemplates', { filter, pagination, sort })
      .then(({ data }) => {
        const mTemplates = [];
        const mTemplatesObject = [];
        data.forEach((value) => {
          mTemplates.push(
            {
              id: value.id,
              name: value.templateName
            }
          )

          mTemplatesObject.push(value);
        });

        setMallTemplates(mTemplates);
        setMallTemplatesObject(arrayToObject(mTemplatesObject, 'id'));
      })
      .catch((error) => {
        console.log(error);
      });

    const cronFilter = { enabled: { $ne: false }, followUtc: { $nin: [true, 'true'] } };
    const cronPagination = { page: 1, perPage: 1000 };
    const cronSort = { field: 'name', order: 'ASC' };

    dataProvider
      .getList('cronTemplates', { filter: cronFilter, pagination: cronPagination, sort: cronSort })
      .then(({ data }) => {
        setCronTemplates(data);
        setCronTemplatesObject(arrayToObject(data, 'id'));

      })
      .catch((error) => {
        console.log(error);
      })
  }, [dataProvider, selectedCompanyId]);

  // Update filename field.
  const updateFilename = ({ companyName, outletName, filename }) => {
    let updatedFilename = '';
    if (filename) {
      updatedFilename = kebabCase(filename);
    } else {
      const compName = companyName ? companyName : form.getFieldState('companyName').value;
      const outName = outletName ? outletName : form.getFieldState('outletName').value;
      updatedFilename = kebabCase(`${compName || ''} ${outName || ''}`);
    }

    form.change('filename', updatedFilename);
  }

  const onChangeCompany = (companyId) => {
    const { name: companyName } = companiesObject && companiesObject[companyId];
    updateChoicesByCompany(companyId)
    form.change('companyName', companyName);
    updateFilename({ companyName });
  }

  const onChangeOutlet = (outletName) => {
    updateChoicesByOutlet(outletName);
    updateFilename({ outletName });
  }

  const onBlurFilename = (event) => {
    if (event.currentTarget && event.currentTarget.value) {
      updateFilename({ filename: event.currentTarget.value });
    }
  }

  return (
    <div className='mall-integration-form'>
      <div className='autocompute-field'>
        <AutocompleteInput
          autocomplete="false"
          source="companyId"
          onChange={onChangeCompany}
          choices={companies || []}
        />
      </div>

      <TextInput type='hidden' source="companyName" style={{ display: 'none' }} />

      <div className='autocompute-field'>
        <AutocompleteInput
          source="outletName"
          label='Outlet Name'
          validate={[required()]}
          onChange={onChangeOutlet}
          choices={outlets || []}
        />
      </div>

      <div>
        <TextInput
          source="filename"
          label='Filename'
          validate={[required()]}
          onBlur={onBlurFilename}
        />
      </div>

      <div className='autocompute-field'>
        <AutocompleteInput
          source="posId"
          label='POS Name'
          validate={[required()]}
          choices={posIds}
          onChange={(e) => {
            form.change('posName', posIdsObject[e].posName);
          }}
        />
      </div>

      <TextInput type='hidden' source="posName" style={{ display: 'none' }} />

      <div className='autocompute-field'>
        <AutocompleteInput
          source="templateId"
          label='Template Name'
          validate={[required()]}
          choices={mallTemplates}
          onChange={(e) => {
            form.change('scriptFilename', mallTemplatesObject[e].scriptFilename);
            form.change('scriptValue', mallTemplatesObject[e].scriptValue);
          }}
        />
      </div>

      <TextInput
        type='hidden'
        source="scriptFilename"
        style={{ display: 'none' }}
      />

      <TextInput
        type='hidden'
        source="scriptValue"
        style={{ display: 'none' }}
      />

      <TextInput
        source="inputOutputFolder"
        label='Input / Output Folder'
      />

      <TextInput
        source="diffDay"
        label='Diff Day'
        validate={[required()]}
      />

      <TextInput
        source="module"
        label='Module'
        validate={[required()]}
      />

      <TextInput
        source="integrator"
        label='Integrator'
        validate={[required()]}
      />

      <TextInput
        source="baseUrl"
        label='Base URL'
        validate={[required()]}
      />

      <div className='autocompute-field'>
        <AutocompleteInput
          source='dbServer'
          label='Database Server'
          validate={[required()]}
          choices={[
            {
              id: 'DB-SERVER 2',
              name: 'DB Server 2'
            },
            {
              id: 'DB-SERVER 3',
              name: 'DB Server 3'
            },
            {
              id: 'DB-SERVER 5',
              name: 'DB Server 5'
            },
            {
              id: 'INDO WEB SERVER',
              name: 'Indo Web Server'
            }
          ]}
        />
      </div>

      <TextInput
        source="customerDb"
        label='Customer DB'
        validate={[required()]}
      />

      <div className='autocompute-field'>
        <AutocompleteInput
          source="timing"
          label='Daily / Hourly'
          style={{ width: '246px' }}
          validate={[required()]}
          choices={[
            {
              id: 'daily',
              name: 'Daily'
            },
            {
              id: 'hourly',
              name: 'Hourly'
            }
          ]}
        />
      </div>

      <TextInput
        source="tenantId"
        label='Tenant ID'
        validate={[required()]}
      />

      <TextInput
        source="tillValue"
        label='Till ID'
        validate={[required()]}
      />

      <TextInput
        source="ftpIp"
        label='FTP IP Address'
        validate={[required()]}
        placeholder='XXX . XXX . XXX . XXX'
      />

      <TextInput
        source="ftpUsername"
        label='FTP Username'
        validate={[required()]}
      />

      <PasswordInput
        source="ftpPassword"
        label='FTP Password'
        validate={[required()]}
      />

      <TextInput
        source="ftpPort"
        label='FTP Port'
        validate={[required()]}
      />

      <div className='autocompute-field'>
        <AutocompleteInput
          source="cronId"
          onChange={(e) => {
            form.change('scheduleTiming', cronTemplatesObject[e].cronExpression);
          }}
          choices={cronTemplates || []} />
      </div>

      <TextInput
        source="scheduleTiming"
        label='Schedule Timing'
        validate={[required()]}
        placeholder='0 4 * * * *'
      />

      <CronTemplateNote />
    </div >
  )
};

const validator = (values) => {
  const errors = {};

  if (values && values.scheduleTiming) {
    const isValidCronExpression = cronFormatValidator({ cronExpression: values.scheduleTiming });
    if (!isValidCronExpression) {
      errors.scheduleTiming = ['Invalid format'];
    }
  }

  return errors;
};

const FormTitle = ({ record }) => {
  return <span>Create Mall Integration</span>;
};

const MallIntegrationCreate = (props) => {
  return (
    <Create title={<FormTitle />}{...props}>
      <SimpleForm redirect="list" validate={validator}>
        <FormDataConsumer>
          {formDataProps => (
            <FormCatcher {...formDataProps} />
          )}
        </FormDataConsumer>
      </SimpleForm>
    </Create >
  )
};

export default MallIntegrationCreate;