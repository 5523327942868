import React from 'react';

import { Show, SimpleShowLayout, TextField } from 'react-admin';

const FormTitle = ({ record }) => {
  return <span>{`${record.templateName}`}</span>;
};

const MallTemplateShow = (props) => {
  return (
    <Show title={<FormTitle />} {...props}>
      <SimpleShowLayout>
        <TextField
          source="templateName"
          label="Template Name"
        />

        <TextField
          source="scriptFilename"
          label="Script Filename"
        />

        <TextField
          source="scriptValue"
          label="Script Value"
        />
      </SimpleShowLayout>
    </Show>
  )
};

export default MallTemplateShow;
